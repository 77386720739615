import { SfNav } from "../../../../node_modules/sf-frontend-base/src/nav/js/navigation";

document.addEventListener("DOMContentLoaded", function(event) {
    const navigation = new SfNav();
    if (typeof navigation.navToggler !== "undefined") {
        navigation.init({
            stickyNav: false,
            linkedMainMenuLinks: false,
            linkedSubMenuLinks: false
        });
    }
});
